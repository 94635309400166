/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default function isolateRugsFilters(data: any) {
  const filtersData: any[] = []

  const getFilterMappings = (templateKey: string) => {
    if (templateKey === 'antique-rugs') {
      return {
        sizeFilter: {
          name: 'filterSize',
          label: 'size',
        },
        colorFilter: {
          name: 'antiquesRugsColor',
          label: 'color',
        },
        regionFilter: {
          name: 'antiqueRugsRegion',
          label: 'region',
        },
      }
    }

    return {
      sizeFilter: {
        name: 'rugSize',
        label: 'size',
      },
      colorFilter: {
        name: 'rugColors',
        label: 'color',
      },
      pileHeightFilter: {
        name: 'rugPileHeight',
        label: 'pile',
      },
      styleFilter: {
        name: 'rugStyle',
        label: 'style',
      },
      designFilter: {
        name: 'rugDesign',
        label: 'design',
      },
      shapeFilter: {
        name: 'shape',
        label: 'shape',
      },
      fiberFilter: {
        name: 'rugFiber',
        label: 'fiber',
      },
    }
  }

  const filterMappings = getFilterMappings(data.templateKey)
  const metafields = data.metafields

  Object.entries(filterMappings).forEach(([key, config]) => {
    if (metafields[key]) {
      let values = metafields[key]

      if (typeof values === 'string' && values.startsWith('[')) {
        try {
          values = JSON.parse(values)
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(`Error parsing ${key}:`, e)
          return
        }
      }

      if (Array.isArray(values) && values.length > 0) {
        const mappedValues = values.map(entry => ({
          name: entry,
          value: entry,
        }))

        filtersData.push({
          name: config.name,
          label: config.label,
          items: mappedValues,
        })
      }
    }
  })

  return filtersData
}

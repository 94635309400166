/** @jsx jsx */
import { Container, jsx, Grid, Box } from 'theme-ui'
import { FC } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { space } from '~/gatsby-plugin-theme-ui'
import { CollectionTemplateType, GatsbyImageType } from '~/@types/models'
import CollectionProducts from '~/containers/collection-products'
import AntiqueRugsProductItem from '~/components/product-item-antique-rugs'
import isolateFilters from '~/utils/filtering'
import CollectionLayout from '~/components/layout/collection-layout'

type Props = CollectionTemplateType

type QueryResult = {
  pageData: {
    frontmatter: {
      header: {
        title: string
        image: GatsbyImageType
        imageMobile?: GatsbyImageType
      }
      secondaryImage?: GatsbyImageType
      contactTitle: string
      contactLA: Array<{
        method: string
        value: string
      }>
      contactNY: Array<{
        method: string
        value: string
      }>
    }
    html: string
  }
}

const CollectionAntiqueRugs: FC<Props> = ({ pageContext: { data } }) => {
  const filterData = isolateFilters(data)

  if (filterData.length) {
    data.filters = filterData
  }

  return (
    <CollectionLayout
      title={data.title}
      seoTitle={data.seo?.title || data.title}
      seoDescription={data.seo?.description || data.description}
      handle={data.handle}
      description={data.description}
      ankleContent={data.metafields?.ankleContent}
      catalog={data.catalog}
      headerDescription={data.metafields?.headerDescription}
      heroHeader={{
        isExternal: true,
        ...(data.imageResponsive && {
          imageSet: data.imageResponsive,
        }),
      }}
      featuredCollectionsList={data.featuredCollections}
    >
      <CollectionProducts
        collectionHandle={data.handle}
        initialData={data.products}
        pagination={data.pagination}
        filters={filterData}
        data={data}
        gridVariant="layout.template-antique-rugs"
      />
    </CollectionLayout>
  )
}

export default CollectionAntiqueRugs
